import React, { useEffect, useState } from 'react';
import ArkButton from './Button';
import Modal from './Modal';
import './Send.css';

let timer;

export default function SendModal({ open, onClose }) {
  // const [error, setError] = useState()
  const [to, setTo] = useState('');
  const [amount, setAmount] = useState('');
  const [outputs, setOutputs] = useState();

  useEffect(() => {
    if (!open) {
      setTo('');
      setAmount('');
      setOutputs();
    }
  }, [open]);

  function debounce(vto, vamount) {
    if (outputs) setOutputs();
    setTo(vto);
    setAmount(vamount);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setOutputs([
        {
          currency: 'USD',
          amount: vamount,
          to: vto,
        },
      ]);
    }, 1000);
  }

  return (
    <Modal
      open={open === 'send'}
      title="Send BSV"
      onClose={onClose}
      showCloseButton={true}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            justifyContent: 'center',
            maxWidth: 300,
          }}
        >
          <input
            className="ark-input"
            type="text"
            placeholder="paymail for BSV address"
            onChange={(e) => debounce(e.target.value, amount)}
            value={to}
          ></input>
          <input
            className="ark-input"
            type="text"
            placeholder="enter amount in USD"
            onChange={(e) => debounce(to, e.target.value)}
            value={amount}
          ></input>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ArkButton outputs={outputs} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
