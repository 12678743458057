import React, { useState, useEffect } from 'react';
import './Button.css';
import * as bsv from 'bsv';
import * as Helper from './helpers';

export default function Button({
  onPayment,
  onError,
  cryptoOperations,
  onCryptoOperations,
  outputs,
  disabled,
}) {
  const [value, setValue] = useState(0);
  const [state, setState] = useState('');
  const [error, setError] = useState();
  const [tx, setTx] = useState();
  const [label, setLabel] = useState('');

  useEffect(() => {
    async function getTx() {
      setTx();
      if (outputs && Helper.isLoggedIn()) {
        try {
          setError();
          setState('loading');
          // Sanity checks
          for (const { currency, amount, address, script, to } of outputs) {
            if (!currency) throw Error(`Invalid currency`);
            if (!address && !to && !script) throw Error(`Enter address`);
            if (!amount || isNaN(Number(amount)) || !(Number(amount) >= 0))
              throw Error(`Enter amount`);
            if (address || to) {
              const output = address || to;
              if (!Helper.isAddress(output) && output.split('@').length !== 2) {
                throw Error(`Enter address`);
              }
            }
          }

          const { utxos, price, address } = await Helper.getUtxos();
          const txb = new bsv.TxBuilder();
          txb.setChangeAddress(bsv.Address.fromString(address));
          txb.sendDustChangeToFees(true);
          const keys = [];
          let outputSatoshis = 0;
          let inputSatoshis = 0;

          for (const utxo of utxos) {
            txb.inputFromPubKeyHash(
              Buffer.from(utxo.txid, 'hex').reverse(),
              utxo.vout,
              bsv.TxOut.fromProperties(
                bsv.Bn(utxo.satoshis),
                new bsv.Script().fromPubKeyHash(
                  bsv.Address.fromString(utxo.address).hashBuf
                )
              )
            );
            const key = Helper.getKey(utxo.derived);
            if (
              bsv.Address.fromPrivKey(key.privKey).toString() !== utxo.address
            ) {
              throw Error(`Utxo key not correct`);
            }
            keys.push(key);
            inputSatoshis += utxo.satoshis;
          }

          for (const { currency, amount, address, script, to } of outputs) {
            let satoshis;
            if (currency.toLowerCase() === 'bsv') {
              satoshis = bsv.Bn(Number(Number(amount) * 100000000).toFixed(0));
            } else if (currency.toLowerCase() === 'usd') {
              satoshis = bsv.Bn((Number(amount) / price) * 100000000);
            } else {
              console.log('Invalid currency', currency);
              continue;
            }
            outputSatoshis += satoshis.toNumber();
            if (script) {
              txb.outputToScript(satoshis, bsv.Script.fromAsmString(script));
            } else {
              let output = address || to;
              if (output && output.includes('@'))
                output = await Helper.lookupPaymail(output);
              txb.outputToAddress(satoshis, bsv.Address.fromString(output));
            }
          }

          if (inputSatoshis <= outputSatoshis)
            throw Error('Insufficient funds');
          txb.build({});
          txb.signWithKeyPairs(keys);
          const fees = txb.estimateFee().toNumber();
          console.log(`${Helper.formatDollars(fees, price)} in fees`);
          const satoshis = outputSatoshis + fees;
          if (satoshis < outputSatoshis) throw Error('Insufficient funds');
          const dollars = Number(price * (satoshis / 100000000));
          let label;
          if (dollars >= 1) {
            label = `$${dollars.toFixed(2).toLocaleString('en-US')}`;
          } else if (dollars >= 0.01) {
            label = `${Number(dollars * 100).toFixed(0)}¢`;
          } else {
            label = `<1¢`;
          }
          setTx(txb.tx.toBuffer().toString('hex'));
          setLabel(label);
          setState('pay');
        } catch (err) {
          if (err.message === 'Failed to fetch') {
            setError(`Couldn't connect`);
          } else {
            setError(err.message);
          }
          setState('error');
        }
      }
    }
    getTx();
  }, [outputs]);

  useEffect(() => {
    if (cryptoOperations && onCryptoOperations && Helper.isLoggedIn()) {
      const keys = [];
      for (const {
        /* name, */ method,
        data,
        dataEncoding,
      } of cryptoOperations) {
        if (method === 'encrypt') {
          const key = Helper.getKey();
          const buf = Buffer.from(data, dataEncoding);
          keys.push(bsv.Ecies.electrumEncrypt(buf, key.pubKey, key));
        } else if (method === 'decrypt') {
          const key = Helper.getKey();
          const buf = Buffer.from(data, dataEncoding);
          keys.push(bsv.Ecies.electrumDecrypt(buf, key));
        }
      }
      onCryptoOperations(keys);
    }
  }, [cryptoOperations, onCryptoOperations]);

  if (!Helper.isLoggedIn()) {
    return (
      <div
        className="slidecontainer"
        style={{
          backgroundColor: '#f08d7d',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ color: 'white' }}>Not logged in</div>
      </div>
    );
  } else if (state === 'loading') {
    return (
      <div className="slidecontainer" style={{ backgroundColor: '#f08d7d' }}>
        <div className="lds-dual-ring"></div>
      </div>
    );
  } else if (state === 'success') {
    return (
      <div
        className="slidecontainer"
        style={{
          backgroundColor: 'green',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ color: 'white', fontWeight: 'bold' }}>Success!</div>
      </div>
    );
  } else if (state === 'error') {
    return (
      <div
        className="slidecontainer"
        style={{
          backgroundColor: '#f08d7d',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ color: 'white' }}>{error || 'Payment failed'}</div>
      </div>
    );
  } else if (!outputs) {
    return (
      <div
        className="slidecontainer"
        style={{
          backgroundColor: '#f08d7d',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
    );
  }
  return (
    <div className="slidecontainer">
      <div className="slidecontainer-one">
        <div style={{}}>{label}</div>
      </div>
      <div className="slidecontainer-two">
        <input
          type="range"
          min="0"
          max="100"
          className="slider"
          id="myRange"
          value={value}
          disabled={!!disabled}
          onChange={(e) => setValue(e.target.value)}
          onMouseUp={async (e) => {
            if (e.target.value === '100') {
              try {
                setState('loading');
                const { txid } = await Helper.broadcastTx({
                  tx,
                  verbose: true,
                });
                onPayment && onPayment({ txid, rawtx: tx });
                setState('success');
              } catch (err) {
                setError(err.message);
                setState('error');
                onError && onError({ error: err.message });
              }
            }
            setValue(0);
          }}
        />
      </div>
    </div>
  );
}
