import { Dialog, withStyles } from '@material-ui/core';
import React from 'react';

const StyledDialog = withStyles({
  // container: {
  //   backgroundColor: '#222',
  //   borderRadius: 50,
  //   border: 1,
  //   color: 'lightgray',
  // },
  paper: {
    backgroundColor: '#222',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'lightgray',
    color: 'white',
  },
})(Dialog);

export default function Modal({
  open,
  onClose,
  children,
  showCloseButton = true,
  title,
}) {
  return (
    <StyledDialog
      open={open}
      onClose={(e, reason) => {
        if (!showCloseButton) {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown')
            return true;
        }
        return onClose(e, reason);
      }}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <div className="relative w-full px-5 py-4">
        {showCloseButton && (
          <button
            onClick={onClose}
            className="rounded-full p-2 absolute top-3 right-3"
            style={{ fontWeight: 'bold', borderWidth: 0 }}
            type="button"
          >
            X
          </button>
        )}
        {title && <h2 className="text-2xl font-bold">{title}</h2>}
        {children}
      </div>
    </StyledDialog>
  );
}
