import React from 'react';
import Modal from './Modal';
import QRCode from 'qrcode.react';

export default function ReceiveModal({ open, onClose, paymail, address }) {
  return (
    <Modal
      open={open === 'receive'}
      title="Deposit BSV"
      onClose={onClose}
      showCloseButton={true}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            justifyContent: 'center',
            maxWidth: 300,
          }}
        >
          {/* <div className='mt-4 text-center'>Deposit BSV</div> */}
          {!!address && (
            <div
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
              }}
            >
              <QRCode
                renderAs="svg"
                includeMargin={true}
                size={120}
                value={address}
              />
            </div>
          )}
          <div className="mt-2 text-center">{paymail}</div>
          <div className="text-center">or</div>
          <div className="mb-4 text-center">{address}</div>
        </div>
      </div>
    </Modal>
  );
}
