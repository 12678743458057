import React, { useEffect, useState } from 'react';
import * as Helper from './helpers';
import Modal from './Modal';
import './Login.css';

export default function LoginModal({ open, onClose }) {
  const [error, setError] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setError();
      setEmail('');
      setPassword('');
    }
  }, [open]);

  return (
    <Modal open={open === 'login'} onClose={onClose} showCloseButton={false}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            maxWidth: 300,
          }}
        >
          <div className="mt-4">Login to your Ark.page wallet</div>
          {!!error && (
            <div style={{ fontWeight: 'bold', color: '#E64931' }}>{error}</div>
          )}
          <input
            className="ark-input"
            type="email"
            placeholder="enter email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <input
            className="ark-input"
            type="password"
            placeholder="enter password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                setError();
                try {
                  await Helper.login({ email, password });
                  onClose(true);
                } catch (err) {
                  setError(err.message);
                }
                setLoading(false);
              }}
              className="ark-button"
            >
              Login
            </button>
            <button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                setError();
                try {
                  await Helper.login({ email, password });
                  onClose(true);
                } catch (err) {
                  try {
                    await Helper.createAccount({ email, password });
                    onClose(true);
                  } catch (err) {
                    setError(err.message);
                  }
                }
                setLoading(false);
              }}
              className="ark-button"
            >
              Signup
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
