import { useEffect, useState } from 'react';
import './App.css';
import * as Helper from './ArkWallet/helpers';
import LoginModal from './ArkWallet/Login';
import ReceiveModal from './ArkWallet/Receive';
import SendModal from './ArkWallet/Send';
import EditPaymailModal from './ArkWallet/EditPaymail';
import EditProfileModal from './ArkWallet/EditProfile';
import { Tooltip } from '@material-ui/core';

function App() {
  const [modal, setModal] = useState();
  const [balance, setBalance] = useState('');
  const [satoshis, setSatoshis] = useState('');
  const [address, setAddress] = useState('');
  const [user, setUser] = useState('');
  const [paymail, setPaymail] = useState('');
  const [image, setImage] = useState('');
  const [history, setHistory] = useState();
  const [price, setPrice] = useState('');

  async function refreshUser(hardRefresh) {
    if (!Helper.isLoggedIn()) return;
    const user = await Helper.getUser();
    setUser(user);
    setPaymail(user.paymail);
    setImage(`${user.image}?time=${+new Date()}`);
  }
  async function refreshWallet() {
    if (!Helper.isLoggedIn()) return;
    const { balance, price, satoshis, address } = await Helper.getUtxos();
    setBalance(balance);
    setPrice(price);
    setAddress(address);
    setSatoshis(satoshis);

    const { history } = await Helper.getHistory();
    setHistory(history);
  }
  function connect() {
    if (!Helper.isLoggedIn()) {
      setModal('login');
    } else {
      setModal();
      refreshUser();
      refreshWallet();
      // setInterval(refresh, 1000 * 10)

      Helper.connectSse(({ onchange, data }) => {
        if (onchange === 'user') refreshUser();
        if (onchange === 'wallet') refreshWallet();
        if (onchange === 'price') {
          if (price !== data) refreshWallet();
        }
      });
    }
  }

  useEffect(() => {
    connect();
  }, []);

  return (
    <div className="App">
      <img
        alt="ark"
        src="/ArkLogo.png"
        width="100"
        height="40"
        style={{ width: 100, height: 40, margin: 20, alignSelf: 'center' }}
      />
      {!!user && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            maxWidth: 500,
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <button
              onClick={() => setModal('edit-profile')}
              style={{ borderWidth: 0, padding: 0 }}
            >
              <img
                alt="profile"
                src={image}
                width="100"
                height="100"
                style={{
                  width: 100,
                  height: 100,
                  margin: 20,
                  borderRadius: 100,
                }}
              />
            </button>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 16,
                }}
              >
                <div className="text-sub">Your Paymail</div>
                <button
                  onClick={() => setModal('edit-paymail')}
                  style={{ borderWidth: 0, padding: 0 }}
                >
                  <div className="text-main">{paymail}</div>
                </button>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 30,
                  }}
                >
                  <div className="text-sub">BSV Price</div>
                  <div className="text-main">
                    {price && Helper.formatDollars(100000000, price)}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="text-sub">Balance</div>
                  <Tooltip
                    title={`${Number(satoshis).toLocaleString(
                      'en-US'
                    )} satoshis`}
                    arrow
                    placement="top"
                  >
                    <div className="text-main">{balance}</div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <button className="ark-button" onClick={() => setModal('receive')}>
              Receive
            </button>
            <button className="ark-button" onClick={() => setModal('send')}>
              Send
            </button>
            <button
              className="ark-button"
              onClick={() => {
                Helper.logout();
                window.location.reload();
              }}
            >
              Logout
            </button>
          </div>
          {!!history && (
            <div style={{ marginTop: 30 }}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr className="text-sub">
                    <th>Transaction History</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((h, i) => {
                    return (
                      <tr key={`${i}`}>
                        <td>
                          <a
                            href={`https://whatsonchain.com/tx/${h.txid}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: 6,
                            }}
                          >
                            <div
                              className="text-main"
                              style={{ fontSize: 14, fontWeight: 200, flex: 1 }}
                            >
                              {h.text}
                            </div>
                            <div className="text-sub">
                              {' '}
                              {Helper.formateDate(h.time)}
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      <LoginModal open={modal} onClose={() => connect()} />
      <ReceiveModal
        open={modal}
        onClose={() => setModal()}
        paymail={paymail}
        address={address}
      />
      <SendModal open={modal} onClose={() => setModal()} />
      <EditPaymailModal
        open={modal}
        onClose={() => setModal()}
        paymail={paymail}
      />
      <EditProfileModal open={modal} onClose={() => setModal()} image={image} />
    </div>
  );
}

export default App;
