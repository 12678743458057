import * as SimpleAes from 'simple-aes-256';
import * as bsv from 'bsv';
import { PaymailClient } from '@moneybutton/paymail-client';
import moment from 'moment';
// import * as BsvPay from 'bsv-pay'
// const BASE_URL = 'http://localhost:3000'
// const BASE_URL = 'http://192.168.1.186:3000'
const BASE_URL = 'https://wallet.ark.page';
const DERIVE_MASTER = 'm/0';

// const pay = new BsvPay({ fetchFunc: window.fetch.bind(window) })
const paymailClient = new PaymailClient();

function formatDollars(satoshis, price) {
  if (satoshis === 0 || price === 0) return `$0.00`;
  const dollars = Number(price * (satoshis / 100000000));
  if (dollars * 100 >= 99) {
    return `$${Number(dollars.toFixed(2)).toLocaleString('en-US')}`;
  } else if (dollars >= 0.01) {
    return `${Number(dollars * 100).toFixed(0)}¢`;
  } else {
    return `<1¢`;
  }
}

function formateDate(date) {
  if (date > +new Date() / 1000 - 60 * 60 * 21) {
    return moment(date * 1000).fromNow();
  } else if (date >= 0) {
    date = moment(date * 1000);
    if (date.year() !== moment().year()) {
      return date.format('Do MMM YYYY');
    } else {
      return date.format('Do MMM');
    }
  } else {
    return '';
  }
}

function isAddress(address) {
  try {
    return bsv.Address.fromString(address).toString() === address;
  } catch (err) {
    return false;
  }
}

function isLoggedIn() {
  return !!localStorage.getItem('ark_keys');
}

function getSeed() {
  const seed = localStorage.getItem('ark_keys');
  if (!seed) throw Error(`Not logged in`);
  return seed;
}

function setSeed(seed) {
  if (bsv.Bip39.fromString(seed).toString() !== seed)
    throw Error(`Invalid seed`);
  localStorage.setItem('ark_keys', seed);
}

function logout() {
  localStorage.removeItem('ark_keys');
  console.log(`Logged out`);
  disconnectSse();
}

function getKey(derived = DERIVE_MASTER) {
  const seed = getSeed();
  const bip39 = bsv.Bip39.fromString(seed);
  const bip32 = bsv.Bip32.fromSeed(bip39.toSeed());
  const key = bip32.derive(derived);
  return key;
}

function getXpub(seed) {
  if (!seed) seed = getSeed();
  const bip39 = bsv.Bip39.fromString(seed);
  const bip32 = bsv.Bip32.fromSeed(bip39.toSeed());
  const xpub = bip32.toPublic().toString();
  return { xpub, bip32, bip39, seed };
}

async function apiCall({ url, method, seed, auth = false, body }) {
  const options = { headers: {} };
  if (body) {
    options.body = JSON.stringify(body);
    options.headers['Content-Type'] = 'application/json';
  }
  if (method) options.method = method;
  if (auth) {
    const { xpub, bip32 } = getXpub(seed);
    const msg = `${+new Date()}`;
    const derived = DERIVE_MASTER;
    const key = bip32.derive(derived);
    const sig = bsv.Bsm.sign(Buffer.from(msg), key);
    options.headers.Authorization = `${xpub},${derived},${msg},${sig}`;
  }
  const res = await fetch(`${BASE_URL}${url}`, options);
  const json = await res.json();
  if (!json || json.error) {
    throw Error((json && json.error) || `Unknown error`);
  }
  return json;
}

async function createAccount({ email, password }) {
  if (!email || !password) throw new Error(`Invalid email or password`);
  email = email.toLowerCase().trim();
  const bip39 = bsv.Bip39.fromRandom();
  const bip32 = bsv.Bip32.fromSeed(bip39.toSeed());
  const xpub = bip32.toPublic().toString();
  const seed = bip39.toString();
  const key = Buffer.from(SimpleAes.sha256(`${email}:${password}`));
  const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex');
  const backup = Buffer.from(SimpleAes.encryptRaw(key, seed)).toString(
    'base64'
  );

  const { user } = await apiCall({
    url: '/api/v1/user',
    method: 'POST',
    auth: true,
    seed,
    body: {
      email,
      backup,
      hash,
    },
  });
  setSeed(seed);
  console.log(`Created new account`, seed, xpub);
  return user;
}

async function login({ email, password }) {
  if (!email || !password) throw new Error(`Invalid email or password`);
  email = email.toLowerCase().trim();
  const key = Buffer.from(SimpleAes.sha256(`${email}:${password}`));
  const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex');
  const { backup, xpub } = await apiCall({
    url: `/api/v1/user/backup/${hash}`,
  });
  const seed = SimpleAes.decryptRaw(
    key,
    Buffer.from(backup, 'base64')
  ).toString();
  setSeed(seed);
  console.log(`Logged in`, seed, xpub);
  if (!xpub) {
    // Legacy. Server does not know xpub from backup
    try {
      await apiCall({
        url: '/api/v1/user',
        method: 'POST',
        auth: true,
        body: {
          email,
          backup,
          hash,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
  return seed;
}

function changePaymail({ paymail }) {
  return apiCall({
    url: `/api/v1/user/paymail`,
    auth: true,
    method: 'POST',
    body: {
      paymail,
    },
  });
}

function changeImage({ image }) {
  return apiCall({
    url: `/api/v1/user/image`,
    auth: true,
    method: 'POST',
    body: {
      image,
    },
  });
}

async function getUtxos() {
  const { xpub } = getXpub();
  const res = await apiCall({ url: `/api/v1/user/utxos/${xpub}` });
  const key = getKey(res.derived);
  res.address = bsv.Address.fromPrivKey(key.privKey).toString();
  return res;
}

async function getUser() {
  const res = await apiCall({ url: `/api/v1/user`, auth: true });
  return res.user;
}

async function lookupPaymail(paymail) {
  if (!paymail) throw Error(`Invalid paymail`);
  console.log(`Looking up paymail:`, paymail);
  const output = await paymailClient.getOutputFor(
    paymail,
    {
      senderHandle: `unknown@ark.page`,
    },
    bsv.PrivKey.fromRandom().toString()
  );
  const script = bsv.Script.fromBuffer(Buffer.from(output, 'hex'));
  const paymailAddress = bsv.Address.fromTxOutScript(script).toString();
  if (bsv.Address.fromString(paymailAddress).toString() !== paymailAddress) {
    throw new Error(`Invalid address`);
  }
  console.log(`Paymail ${paymail} address:`, paymailAddress);
  return paymailAddress;
}

async function broadcastTx({ tx }) {
  // const { txid } = await pay.broadcast({ tx, verbose: false })
  // return txid
  return apiCall({
    url: `/api/v1/user/broadcast`,
    auth: true,
    method: 'POST',
    body: {
      tx,
    },
  });
}

async function getHistory() {
  return apiCall({
    url: `/api/v1/user/history`,
    auth: true,
  });
}

let sse;

function connectSse(onChange) {
  const { xpub } = getXpub();
  const sse = new EventSource(
    `https://wallet.ark.page/api/v1/user/sse/${xpub}`
  );
  sse.onmessage = (event) => {
    const obj = JSON.parse(event.data);
    console.log(`sse message`, obj);
    onChange(obj);
  };
}

function disconnectSse() {
  try {
    sse.onmessage = null;
    sse.close();
  } catch (err) {}
  sse = null;
}

export {
  createAccount,
  getUser,
  getSeed,
  login,
  logout,
  changePaymail,
  getUtxos,
  changeImage,
  lookupPaymail,
  broadcastTx,
  getKey,
  isLoggedIn,
  getHistory,
  formatDollars,
  isAddress,
  formateDate,
  connectSse,
};
