import React, { useEffect, useState } from 'react';
import * as Helper from './helpers';
import Modal from './Modal';

export default function EditPaymailModal({
  open,
  onClose,
  paymail: oldPaymail,
}) {
  const [error, setError] = useState();
  const [paymail, setPaymail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setError();
      setPaymail('');
      setLoading(false);
    }
  }, [open]);

  return (
    <Modal
      open={open === 'edit-paymail'}
      onClose={onClose}
      showCloseButton={true}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            maxWidth: 300,
            paddingBottom: 20,
          }}
        >
          <div className="mt-4">Change your paymail</div>
          {!!error && <div className="ark-error">{error}</div>}
          <input
            className="ark-input"
            type="email"
            placeholder={`Current paymail is ${oldPaymail}`}
            onChange={(e) => setPaymail(e.target.value)}
            value={paymail}
          ></input>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              disabled={loading}
              onClick={async () => {
                setError();
                setLoading(true);
                try {
                  await Helper.changePaymail({ paymail });
                  onClose(true);
                } catch (err) {
                  setError(err.message);
                }
                setLoading(false);
              }}
              className="ark-button"
            >
              Change Paymail
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
