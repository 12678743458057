import React, { useEffect, useState } from 'react';
import * as Helper from './helpers';
import Modal from './Modal';
import Resizer from 'react-image-file-resizer';

export default function EditProfileModal({ open, onClose, image }) {
  const [error, setError] = useState();

  useEffect(() => {
    if (!open) {
      setError();
    }
  }, [open]);

  return (
    <Modal
      open={open === 'edit-profile'}
      onClose={onClose}
      showCloseButton={true}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'center',
            maxWidth: 300,
            paddingBottom: 20,
          }}
        >
          <div className="mt-4">Change your image</div>
          {!!error && <div className="ark-error">{error}</div>}
          <img
            alt="profile"
            src={image}
            width="100"
            height="100"
            style={{ width: 100, height: 100, margin: 20, borderRadius: 100 }}
          />
          <input
            id="image-picker"
            placeholder="Choose image"
            style={{ width: 210 }}
            onChange={(event) => {
              const file = event.target.files[0];
              Resizer.imageFileResizer(
                file,
                400,
                400,
                'JPEG',
                100,
                0,
                async (image) => {
                  try {
                    setError();
                    await Helper.changeImage({ image });
                    onClose(true);
                  } catch (err) {
                    setError(err.message);
                  }
                },
                'base64'
              );
            }}
            type="file"
          />
        </div>
      </div>
    </Modal>
  );
}
